<template>
  <div class="container">
    <van-search class="self-search" v-model="keyword" show-action placeholder="请输入搜索关键词">
      <template #action>
        <div @click="getList('search')">搜索</div>
      </template>
    </van-search>

    <div class="btns-tab">
      <a class="btns-tab-item" :class="{'active': activityType === 1}" @click="switchActivityType(1)" href="javascript:;">线上</a>
      <a class="btns-tab-item" :class="{'active': activityType === 2}" @click="switchActivityType(2)" href="javascript:;">线下</a>
    </div>

    <van-empty :description="word" v-if="list.length == 0" />
    <div class="news-list" v-if="list.length > 0">
      <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onRefresh"
      >
        <div v-for="(item, index) in list" :key="index" class="news-card" @click="toBVueInfo('/organDetail', item.id)">
          <div class="news-desc" @click="toBVueInfo('/organDetail', item.id)">
            <p class="news-title">{{item.title}}</p>
            <div class="news-span">
              <p>{{item.time}}}</p>
              <p>{{item.view_num}}浏览</p>
            </div>
          </div>
          <div class="list-img-box">
            <img :src="item.cover" alt="">
          </div>
        </div>
      </van-list>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      keyword: '',
      activityType: 1,
      list: [],
      word: '数据加载中...',
      loading: false,
      finished: false,
      refreshStatus: false,
      formData: {
        'offset':0,
        'limit': 10,
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onRefresh() {
      this.getList()
    },
    toBVueInfo(toPath, toid) {
      this.$router.push({path: toPath, query: {id: toid, action: 'activity'}})
    },
    getList(type = '') {
      let that = this
      if (that.refreshStatus) {
        return false
      }
      that.formData.id = that.activityType
      that.formData.keyword = that.keyword
      if (that.formData.offset == 0 || type == 'search') {
        that.list = []
      }
      this.$api.LEARN_ACTIVITY_GET(that.formData).then(res => {
        if (that.formData.offset > 0 && res.length == 0) {
          that.finished = true
          that.refreshStatus = false
        }
        if (res.length === 0) {
          that.word = '暂无数据'
          that.refreshStatus = false
          return false
        }
        res.forEach((item) => {
          that.list.push({
            id: item.id,
            title: item.title,
            view_num: item.view_num,
            type: Number(item.type),
            cover: item.cover ? item.cover : '',
            time: item.create_time
          })
        })
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        } else {
          that.finished = true
        }
        that.refreshStatus = false
      })
    },
    switchActivityType(type){
      this.activityType  = type
      this.getList()
    },
    toBVue(toPath) {
      this.$router.push({path: toPath})
    }
  },
}
</script>
<style lang="less" scoped>
</style>